.timer {
    display: flex;
    font-size: 20px;
    justify-content: center;
  }
  .timer > div {
    padding: 10px;
    background: #444;
    color: #fff;
    font-size: 30px;
    margin-right: 2px;
    width: 100px;
    display: flex;
    justify-content: center;
  }
  .timer > div > span {
    text-transform: uppercase;
    color: #999;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .expired {
    font-size: 20px;
    color: rgb(126, 49, 49);
    border: 1px solid rgb(126, 49, 49);
    padding: 20px;
  }
  