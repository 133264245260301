.cleanUp {
    padding: 100px 0;
    background-image: url("../../../images/background/bg26.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cleanup_pbutton {
    align-items: center;
    text-align: center;
}

.cleanUp_p {
    padding-bottom: 50px;
}

.redbutton {
    background-color: #f01111;
    padding: 15px;
    border-radius: 30px;
    color: #fff;
    border: none;
}
.cleanUp_p>p{
    font-weight: bolder;
    
}
.redbutton:hover {
    cursor: pointer;
}