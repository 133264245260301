.courseDetails {
  background-color: #02024f;
}
.courseDetails {
  display: flex;
  width: 90%;
  margin: auto;
  padding: 20px 0;
}
.course__overview {
  color: #fff;
  padding: 20px;
}
.course__overview > p {
  font-size: 1.5rem;
}
.course__overview2 {
  width: 90%;
  margin: auto;
}
.course__uwilllearn {
  background-color: #fefdfd;
  border-radius: 25px;
  padding: 20px;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
}
.card__testimon {
  display: flex;
  flex-wrap: wrap;
}
/* testimonials */
.donttake__ourwords {
  width: 80%;
  margin: auto;
}
.donttake__ourwords > p {
  width: 90%;
  margin: auto;
}
.donttake__ourwords > h2 {
  width: 70%;
  margin: auto;
}
.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 50px;
  border: none;
  width: 300px;
  margin: 10px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card__image__testimonial {
  border-radius: 50px;
  width: 90px;
  height: 90px;
  /* object-fit: contain; */
}
.card__testimon {
  width: 95%;
  margin: auto;
}

@media screen and (max-width: 450px) {
  .courseDetails {
    display: flex;
    flex-direction: column;
  }
  /* testimonials */
  .donttake__ourwords {
    width: 95%;
    margin: auto;
  }
  .donttake__ourwords > p {
    width: 90%;
    margin: auto;
  }
  .donttake__ourwords > h2 {
    width: 95%;
    margin: auto;
  }
  .card {
    background-color: #fff;
    padding: 20px;
    border-radius: 50px;
    border: none;
    width: 100%;
    margin: 10px;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .course__overview2 > h1 {
    font-size: 1.6rem;
  }
  .course__uwilllearn > h1 {
    font-size: 1.6rem;
  }
  .donttake__ourwords > h2 {
    font-size: 1.6rem;
  }
}
/* ipad mini */

@media screen and (max-width: 820px) {
  .courseDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card__testimon {
    width: 96%;
    margin: auto;
  }
  .card {
    margin: auto;
    width: 370px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .card > p {
    font-size: 1rem;
  }
  .course__overview2 > h1 {
    font-size: 1.6rem;
  }
  .course__uwilllearn > h1 {
    font-size: 1.6rem;
  }
  .donttake__ourwords > h2 {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .courseDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card__testimon {
    width: 96%;
    margin: auto;
  }
  .card {
    margin: auto;
    width: 350px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .card > p {
    font-size: 1rem;
  }
  .course__overview2 > h1 {
    font-size: 1.6rem;
  }
  .course__uwilllearn > h1 {
    font-size: 1.6rem;
  }
  .donttake__ourwords > h2 {
    font-size: 1.6rem;
  }
  .course__overview2>p{
      font-size: 1rem;
  }
  .donttake__ourwords{
      width:95%;
      margin: auto;
  }
    
  .donttake__ourwords>p{
      font-size: 1rem;
  }
}
