.program{
    height: 100vh;
    background-color: red;
    padding:250px 0;
    color: #fff;
    background-image: url(./European_Council_38185339475.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
 
}
.program>h1{
    color: #fff;
    font-size: 5rem;
}
.program>p{
    color: #fff;
    font-size: 3rem;
    padding:30px 0
}
.empty_div{
    border:2px solid white;
 
    width: 40%;
    margin: auto;
}
/* other event  */
.program__event{
    padding:100px 70px;
}
.programme__image{
    background-color: #FF7575;
    padding-bottom: 20px;
   padding-left: 20px;
    width: 270px;
}
.program__event__wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.programme__image:hover{
    transform: scale(1.1);
    cursor: pointer;
}
.programme_images{
    height: 250px;
    width: contain;
}
@media screen and (max-width:446px){
    /* home */
    .program{
        padding:250px 5px;
    }
    .program>h1{
        color: #fff;
        font-size: 2rem;
    }
    .program>p{
        color: #fff;
        font-size: 1.5rem;
       
    }
    .empty_div{
        border:2px solid white;
     
        width: 50%;
        margin: auto;
    }
    /* other event */
    .program__event{
        padding:10px 10px
    }
    .programme__image{
        background-color: #FF7575;
        padding-bottom: 20px;
       padding-left: 20px;
        width: 385Px;
        margin: auto
         
    }
    .programme_images{
        height: 300px;
        width: 100%;
    }
     .program__event__wrap{
         width:100%;
         margin: auto
     }
}
@media screen and (max-width:820px){
    /* home */
    .program>h1{
        color: #fff;
        font-size: 4rem;
    }
    .program>p{
        color: #fff;
        font-size: 2rem;
        padding:15px 0
    }
    .empty_div{
        border:2px solid white;
     
        width: 60%;
        margin: auto;
    }
}
@media screen and (max-width:768px){
    /* home */
    .program>h1{
        color: #fff;
        font-size: 4rem;
    }
    .program>p{
        color: #fff;
        font-size: 2rem;
        padding:15px 0
    }
    .empty_div{
        border:2px solid white;
     
        width: 60%;
        margin: auto;
    }
}