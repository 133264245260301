.young__webinar {
  background-color: rgb(146, 63, 63);
}
.webinar {
  width: 60%;
  margin: auto;
  text-align: center;
  padding: 200px 0;
}
.webinar__button {
  padding: 15px;
  background-color: #fbbc04;
  margin: 5px;
  border: none;
  border-radius: 15px;
  font-size: 1.5rem;
}
.webinar__button:hover {
  cursor: pointer;
  color: white;
  font-weight: bolder;
}
/* beneficials */
.webinar__beneficial {
  padding: 100px 0;
}
.webinar__beneficial > p {
  font-size: 1.5rem;
}
.young__webinar__beneficial {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 85%;
  margin: auto;
  padding-top: 20px;
}

.beneficial__webinar {
  margin: 5px;
  text-align: center;
  padding-bottom: 50px;
}
/* webinar video display */
.beneficial__video {
  width: 80%;
  margin: auto;
  justify-content: space-between;
  display: flex;
  padding: 20px 0;
}
.beneficial__video__text {
  padding: 20px;
}
/* counter */
.counter__young__webinar {
  background-color: #404040;
  padding: 50px 0;
}
/* what u will get */
.whatuwill__container {
  width: 80%;
  margin: auto;
}
.add__space {
  justify-content: space-between;
}
.youwill__get {
  text-align: start;
}
.youwill__get > h5 {
  padding-top: 20px;
}
.icon__adding {
  font-size: 3.5rem;
  color: red;
  margin-bottom: 20px;
}
.whatuwill__container > h2 {
  color: #8e8e8e;
}
/* expert */
.our__mentors {
  padding: 20px 0;
}
.uwill_get {
  height: 200px;
  width: 100%;
}
.what_uwillget {
  text-align: center;
}
.what__uwill__study__container {
  width: 70%;
  margin: auto;
}
.what__uwill__study {
  justify-content: space-between;
}
/* Testimonial */
.testimonial {
  width: 80%;
  margin: auto;
  background-color: #f4f5f8;
}
.testimonial__backgrnd {
  padding: 40px 0;
  background-color: #f4f5f8;
}
/* limted students */
.limited__students {
  background-color: #404040;
  font-size: 3rem;
  font-weight: bolder;
  padding: 150px 0;
  text-align: center;
}
.limited__button {
  background-color: #fbbc04;
  font-size: 1.6rem;
  padding: 15px 50px;
  border: none;
  border-radius: 15px;
}
.limited__button:hover {
  cursor: pointer;
  color: white;
}
/* agender */
.webinar__Agender {
  width: 80%;
  margin: auto;
}
/* webinar footer */
.webinar__final__work {
  background-color: #df2033;
}
.webinar__footer {
  width: 80%;
  margin: auto;
  padding: 50px 0;
  text-align: center;
  align-items: center;
  display: flex;
  color: white;
  justify-content: space-between;
  font-weight: bolder;
  padding-bottom: 150px;
}
.webinar_logo > img {
  width: 150px;
  height: 150px;
}
.social__media {
  margin: 10px;
  font-size: 1.7rem;
}
.social__media:hover {
  cursor: pointer;
}
/* fixed div */

.fixed__div {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fixed__div__pay > h2 {
  font-size: 1.5rem;
  line-height: 1rem;
  margin-left: 10px;
}
.fixed__div__midile > p {
  font-size: 1.5rem;
  line-height: 1rem;
}
/* how to participate */
.participate {
  background-color: rgb(26, 8, 61);
  padding: 30px 10px;
  width: 100%;
  margin: auto;
   
}
.participate__instruction {
  width: 90%;
  margin: auto;
  padding-bottom: 10px;
}

@media screen and (max-width: 446px) {
  .webinar {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
  }
  /* beneficials */
  .webinar__beneficial {
    padding: 20px 0;
  }
  .webinar__beneficial > p {
    font-size: 1.5rem;
  }

  .young__webinar__beneficial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding-top: 20px;
  }
  .beneficial__text {
    padding: 0 4px;
  }

  .beneficial__webinar {
    margin: 5px;
    text-align: center;
    padding-bottom: 20px;
  }
  /* webinar video display */
  .beneficial__video {
    width: 95%;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .beneficial__video__text {
    padding: 0px;
  }
  .video {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  /* what u will get */
  .whatuwill__container {
    width: 90%;
    margin: auto;
  }
  .icon__adding {
    font-size: 2.5rem;
    color: red;
    margin-bottom: 15px;
  }
  .youwill__get > p {
    font-size: 1.5rem;
  }
  .whatuwill__container > h2 {
    color: #8e8e8e;
    font-size: 1.5rem;
  }
  /* what__uwill__study__container */
  .what__uwill__study__container {
    width: 90%;
    margin: auto;
  }
  .what_uwillget {
    width: 100%;
  }
  .uwill_get {
    height: 250px;
    width: 100%;
  }
  .testimonial {
    width: 90%;
    margin: auto;
  }

  .limited__students {
    background-color: #404040;

    font-weight: bolder;
    padding: 80px 0;
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .limited__students > h2 {
    font-size: 1.5rem;
  }
  /* agender */
  .webinar__Agender {
    width: 90%;
    margin: auto;
    padding: 20px 0;
  }
  /* webinar footer */
  .webinar__final__work {
    background-color: #df2033;
    padding: 0 15px;
  }
  .webinar__footer {
    width: 90%;
    margin: auto;
    padding: 20px 10px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: space-between;
    font-weight: bolder;
    padding-bottom: 300px;
  }

  .webinar_logo > img {
    width: 110px;
    height: 110px;
  }
  .social__media {
    margin: 10px;
    font-size: 1.7rem;
  }
  .webinar__info > p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    font-weight: bolder;
  }
   
}
@media screen and (max-width: 768px) {
  .webinar {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
  }
  /* beneficials */
  .webinar__beneficial {
    padding: 20px 0;
  }
  .webinar__beneficial > p {
    font-size: 1.5rem;
  }

  .young__webinar__beneficial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding-top: 20px;
  }
  .beneficial__text {
    padding: 0 4px;
  }

  .beneficial__webinar {
    margin: 5px;
    text-align: center;
    padding-bottom: 20px;
  }
  /* webinar video display */
  .beneficial__video {
    width: 95%;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .beneficial__video__text {
    padding: 0px;
  }
  .video {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 820px) {
  .webinar {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
  }
  /* beneficials */
  .webinar__beneficial {
    padding: 20px 0;
  }
  .webinar__beneficial > p {
    font-size: 1.5rem;
  }

  .young__webinar__beneficial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding-top: 20px;
  }
  .beneficial__text {
    padding: 0 4px;
  }

  .beneficial__webinar {
    margin: 5px;
    text-align: center;
    padding-bottom: 20px;
  }
  /* webinar video display */
  .beneficial__video {
    width: 95%;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .beneficial__video__text {
    padding: 0px;
  }
  .video {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 900px) {
  .webinar {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
  }
  /* beneficials */
  .webinar__beneficial {
    padding: 20px 0;
  }
  .webinar__beneficial > p {
    font-size: 1.5rem;
  }

  .young__webinar__beneficial {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding-top: 20px;
  }
  .beneficial__text {
    padding: 0 4px;
  }

  .beneficial__webinar {
    margin: 5px;
    text-align: center;
    padding-bottom: 20px;
  }
  /* webinar video display */
  .beneficial__video {
    width: 95%;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .beneficial__video__text {
    padding: 0px;
  }
  .video {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 1024px) {
  .webinar {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
  }
  /* beneficials */
  .webinar__beneficial {
    padding: 20px 0;
  }
  .webinar__beneficial > p {
    font-size: 1.5rem;
  }

  .young__webinar__beneficial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding-top: 20px;
  }
  .beneficial__text {
    padding: 0 4px;
  }

  .beneficial__webinar {
    margin: 5px;
    text-align: center;
    padding-bottom: 20px;
  }
  /* webinar video display */
  .beneficial__video {
    width: 95%;
    margin: auto;
    justify-content: space-between;
    display: flex;

    padding: 20px 0;
  }
  .beneficial__video__text {
    padding: 0 10px;
    width: 100%;
  }
  .video {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
