/* Elearning */
.elearning {
  background-image: url(./windows-11-logo-hero.jpg);
  border-image-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 250px 0;
}
.elearning > button {
  background-color: #f24726;
  border: none;
  padding: 20px;
  font-size: 1rem;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
}
.elearning > button:hover {
  cursor: pointer;
  color: yellow;
}
/* overview */
.elearning__overview {
  padding: 30px 0 30px 0;
  width: 80%;
  margin: auto;
}
.elearning__overview > p {
  font-size: 1.5rem;
}
/* about elearning */
.elearning__about {
  background-color: red;
}
.elearning__about > p {
  width: 80%;
  padding: 80px 30px;
  margin: auto;
  font-size: 1.5rem;
  color: white;
}
.elearning__benefit__item {
  display: flex;
  align-items: center;
  background-color: #fafafa;
}
.elearning__benefit {
  width: 80%;
  margin: auto;
  padding: 50px 0;
  background-color: #fafafa;
}
.elearning__benefit__left__image {
  margin-right: 20px;
  width: 100%;
  height: 400px;
}
/* secretaires */
.elearning__ecretariats {
  width: 90%;
  margin: auto;
  padding: 50px 0;
}
.elearning__flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.elearning__card {
  align-items: center;
  text-align: center;
  padding: 20px;
  margin-bottom: 7px;
  border: 1px solid gray;
}
.elearning__card:hover {
  transform: scale(0.9);
  border: 1px solid rgb(235, 14, 14);
}
.elearning__card > img {
  height: 250px;
  width: 100%;
}
.elearning__card > h4 {
  padding-top: 10px;
}
/* joing */
.elearning__join {
  background-color: red;
  color: #fafafa;
}
.elearning__join > p {
  text-align: center;
  width: 60%;
  margin: auto;
  font-size: 2.5rem;
  padding: 50px 0;
  line-height: 3rem;
  font-weight: bolder;
}
/* joing now */
.elearning__join__now {
  background-image: url(./2016-11-20-SANMUN01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.elearning__join__now > p {
  width: 80%;
  margin: auto;
  padding: 100px 0;
  font-size: 2rem;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  line-height: 3rem;
}
/* testiomonial */
.elearning__testimonials {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0;
}
.elearning__testimonials__cards {
  width: 600px;
}
.testimonial__empty__div {
  border-bottom: 1px solid gray;
}

/* noted */
.elearning__noted {
  background-image: url(./2016-11-20-SANMUN01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
}
.elearning__noted > p {
  width: 80%;
  margin: auto;

  font-size: 2rem;
  line-height: 3rem;
  color: #fff;
}
/* media partner */
.elearning__asking__image {
  text-align: center;
  padding: 0 5px;
}
.elearning__asking__image > img {
  width: 100%;
  height: 100%;
}
.elearning__asking__question {
  width: 80%;
  margin: auto;
}
.connect__withus{
  background-color: red;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bolder;
  padding:30px 0;
}
.social__media__connect{
  margin: 7px;
}
.social__media__connect:hover{
  cursor: pointer;
}
.connect__empty__div{
  border-bottom: 3px solid #FBBC04;
  width: 20%;
  margin: auto;
  padding-bottom: 20px;
}
@media screen and (max-width: 446px) {
  /* Elearning */
  .elearning {
    background-image: url(./windows-11-logo-hero.jpg);
    background-repeat: no-repeat;
  background-size: cover;
    text-align: center;
    padding: 50px 7px;
    text-align: center;
  }
  .elearning > button {
    background-color: #f24726;
    border: none;
    padding: 20px;
    font-size: 1rem;
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
  }
  .elearning > button:hover {
    cursor: pointer;
    color: yellow;
  }
  /* benefit */
  .elearning__benefit__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fafafa;
  }
  .elearning__benefit {
    width: 90%;
    margin: auto;
    padding: 50px 0;
    background-color: #fafafa;
  }
  /* overview */
  .elearning__overview {
    padding: 30px 0;
    width: 90%;
    margin: auto;
  }
  .elearning__overview > p {
    font-size: 1.5rem;
  }
  /* about elearning */
  .elearning__about {
    background-color: red;
  }
  .elearning__about > p {
    width: 90%;
    padding: 20px 5px;
    margin: auto;
    font-size: 1.5rem;
    color: white;
  }
  /* joing */
  .elearning__join {
    background-color: red;
    color: #fafafa;
  }
  .elearning__join > p {
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
    padding: 50px 0;
    line-height: 2rem;
    font-weight: bolder;
  }
  /* secretaires */
  .elearning__ecretariats {
    width: 90%;
    margin: auto;
    padding: 20px 0;
  }
  .elearning__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .elearning__card {
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 7px;
    border: 1px solid gray;
    width: 100%;
  }
  .elearning__card:hover {
    transform: scale(0.9);
    border: 1px solid rgb(235, 14, 14);
  }
  .elearning__card > img {
    height: 100%;
    width: 100%;
  }
  .elearning__card > h4 {
    padding-top: 10px;
  }
  /* noted */
  .elearning__noted {
    background-image: url(./2016-11-20-SANMUN01.jpg);
    background-repeat: no-repeat;
  background-size: cover;
    padding: 50px 0;
  }
  .elearning__noted > p {
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
    line-height: 3rem;
    color: #fff;
  }
  .elearning__asking__question {
    width: 90%;
    margin: auto;
  }
}

/* ipad mini */
@media screen and (max-width: 768px) {
  /* Elearning */
  .elearning {
    background-image: url(./windows-11-logo-hero.jpg);
    background-repeat: no-repeat;
  background-size: cover;
    text-align: center;
    padding: 50px 10px;
    text-align: center;
  }
  .elearning > button {
    background-color: #f24726;
    border: none;
    padding: 20px;
    font-size: 1rem;
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
  }
  .elearning > button:hover {
    cursor: pointer;
    color: yellow;
  }
  /* benefit */
  .elearning__benefit__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fafafa;
  }
  .elearning__benefit {
    width: 90%;
    margin: auto;
    padding: 50px 0;
    background-color: #fafafa;
  }
  /* overview */
  .elearning__overview {
    padding: 30px 0;
    width: 90%;
    margin: auto;
  }
  .elearning__overview > p {
    font-size: 1.5rem;
  }
  .elearning__benefit__left__image {
    margin-right: 20px;
    width: 100%;
    height: 600px;
  }
  /* about elearning */
  .elearning__about {
    background-color: red;
  }
  .elearning__about > p {
    width: 90%;
    padding: 20px 5px;
    margin: auto;
    font-size: 1.5rem;
    color: white;
  }
  /* joing */
  .elearning__join {
    background-color: red;
    color: #fafafa;
  }
  .elearning__join > p {
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
    padding: 50px 0;
    line-height: 2rem;
    font-weight: bolder;
  }
  /* secretaires */
  .elearning__ecretariats {
    width: 90%;
    margin: auto;
    padding: 50px 0;
  }
  .elearning__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .elearning__card {
    align-items: center;
    text-align: center;
    margin-bottom: 7px;
    border: 1px solid gray;
    width: 100%;
    margin-bottom: 10px;
  }
  .elearning__card:hover {
    transform: scale(0.9);
    border: 1px solid rgb(235, 14, 14);
  }
  .elearning__card > img {
    height: 600px;
    width: 100%;
  }
  .elearning__card > h4 {
    padding-top: 10px;
  }
  /* noted */
  .elearning__noted {
    background-image: url(./2016-11-20-SANMUN01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
  }
  .elearning__noted > p {
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
    line-height: 3rem;
    color: #fff;
  }
  .elearning__asking__question {
    width: 90%;
    margin: auto;
  }
}
@media screen and (max-width: 820px) {
  /* Elearning */
  .elearning {
    background-image: url(./windows-11-logo-hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 50px 10px;
    text-align: center;
  }
  .elearning > button {
    background-color: #f24726;
    border: none;
    padding: 20px;
    font-size: 1rem;
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
  }
  .elearning > button:hover {
    cursor: pointer;
    color: yellow;
  }
  /* benefit */
  .elearning__benefit__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fafafa;
  }
  .elearning__benefit {
    width: 90%;
    margin: auto;
    padding: 50px 0;
    background-color: #fafafa;
  }
  /* overview */
  .elearning__overview {
    padding: 30px 0;
    width: 90%;
    margin: auto;
  }
  .elearning__overview > p {
    font-size: 1.5rem;
  }
  .elearning__benefit__left__image {
    margin-right: 20px;
    width: 100%;
    height: 600px;
  }
  /* about elearning */
  .elearning__about {
    background-color: red;
  }
  .elearning__about > p {
    width: 90%;
    padding: 20px 5px;
    margin: auto;
    font-size: 1.5rem;
    color: white;
  }
  /* joing */
  .elearning__join {
    background-color: red;
    color: #fafafa;
  }
  .elearning__join > p {
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
    padding: 50px 0;
    line-height: 2rem;
    font-weight: bolder;
  }
  /* secretaires */
  .elearning__ecretariats {
    width: 90%;
    margin: auto;
    padding: 50px 0;
  }
  .elearning__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .elearning__card {
    align-items: center;
    text-align: center;
    margin-bottom: 7px;
    border: 1px solid gray;
    width: 100%;
    margin-bottom: 10px;
  }
  .elearning__card:hover {
    transform: scale(0.9);
    border: 1px solid rgb(235, 14, 14);
  }
  .elearning__card > img {
    height: 600px;
    width: 100%;
  }
  .elearning__card > h4 {
    padding-top: 10px;
  }
  /* noted */
  .elearning__noted {
    background-image: url(./2016-11-20-SANMUN01.jpg);
    background-repeat: no-repeat;
  background-size: cover;
    padding: 50px 0;
  }
  .elearning__noted > p {
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
    line-height: 3rem;
    color: #fff;
  }
  .elearning__asking__question {
    width: 90%;
    margin: auto;
  }
}
