.benefitCard {
  width: 95%;
  margin: auto;
  background-color: transparent;
  margin-bottom: 60px;
}
.benefitCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.benefitCard__details {
  text-align: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 60px;
  /* background-color: #fff; */
  /* box-shadow: -3px -2px 30px 14px rgba(0,0,0,0.425); */
  border-radius: 25px;
  height: 200px;
  width: 300px;
  border: 1px solid red;
  padding: 20px 20px 50px 20px;
}
.benefitCard__details > p {
  font-size: 1rem;
  font-weight: bolder;
  color: rgb(95, 95, 150);
  font-family: Arial, Helvetica, sans-serif;
  /* padding: 20px; */
  padding: 0;
  margin: 0;
}
.benefit__images {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 450px) {
  .benefitCard {
    width: 99%;
    margin: auto;
    margin-bottom: 200px;
  }
  .benefitCard__details {
    width: 100%;
    
    margin-bottom: 500px;
  }
}

@media screen and (max-width: 820px) {
  .benefitCard {
    width: 99%;
    margin: auto;
    margin-bottom: 60px;
  }
  .benefitCard__details {
    width: 100%;
    margin-bottom: 200px;
    height: 250px;
    width: 350px;
    margin: auto;
  }
  .benefitCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
