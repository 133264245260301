.webdev {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  padding: 25px 0;
}
.coureName {
  border: 1.9px solid gray;
  /* margin: 0 10px 10px 0; */
  width: 30%;
  margin: auto;
  margin-bottom: 15px;
  padding: 5px;
}
.coureName > img {
  height: 200px;
  width: 100%;
}
.coureName > h1 {
  font-size: 1.5rem;
  color: gray;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}
.text__header {
  font-size: 1.5rem;
  color: gray;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}
.text__p {
  font-size: 1.2rem;
  color: gray;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}
.coureName__button {
  cursor: pointer;
  border: none;
  padding: 10px;
  font-style: italic;
  font-size: 1.3rem;
  color: #fff;
  background: #0f73ed;
}
.coureName__button:hover {
  color: greenyellow;
  font-weight: bolder;
}
.readmore {
  color: #0f73ed;
}
.readmore:hover {
  cursor: pointer;
}
/* powerfull message */
.powerfull__message {
  display: flex;
  width: 85%;
  margin: auto;
  padding: 50px 0;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  justify-content: space-between;
}
.powerful__right__card {
  width: 300px;
  border-radius: 15px;
  background-color: #0f73ed;
  padding: 20px;
  text-align: center;
  color: #fff;
}
.heading__h3 {
  font-size: 1.5rem;
  color: gray;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  /* font-family: Monotype Corsiva, cursive; */
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  text-align: center;
}
.button__powerfull {
  background-color: #0f73ed;
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 10px;
  font-weight: bolder;
  font-size: 1.1rem;
  color: #fff;
}
.button__powerfull:hover {
  cursor: pointer;
  color: aqua;
}
/* bulletpoints */
.bulletPoint {
  display: flex;
}
/* typewritter */
.typeScriptWritter {
  background-image: url(./alitype.gif);
  background-repeat: no-repeat;
  background-position: center;
  /* padding: 400px 50px; */
  border-image: round;
  /* background-size: cover; */
  /* height: 100%; */
}

.join__nowButton {
  text-align: center;
  margin: 20px;
  background-color: #FF0000;
  font-size: xx-large;
  font-weight: bolder;
  padding: 0 20px;
  border:none;
  border-radius: 15px;
  color:#fff
}
.join__nowButton:hover{color:#cced0f; cursor: pointer}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .webdev {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    padding: 15px 0;
  }
  .coureName {
    border: 1.9px solid gray;
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    padding: 5px;
  }
  .coureName > img {
    height: 200px;
    width: 100%;
  }
  .coureName > h1 {
    font-size: 1.5rem;
    color: gray;
    margin: 0;
    padding: 0;
    font-weight: bolder;
    /* font-family: Monotype Corsiva, cursive; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  .coureName > p {
    font-style: 1.3rem;
    text-align: center;
  }
  .coureName {
    text-align: center;
  }
  .powerfull__message {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    padding: 50px 0;
    background-color: #fff;
    border-radius: 20px;
    padding: 15px;
    justify-content: space-between;
  }
  .powerful__right__card {
    width: 100%;
    border-radius: 15px;
    background-color: #0f73ed;
    padding: 20px;
    text-align: center;
    color: #fff;
  }
  .heading__h3 {
    font-size: 1.5rem;
    color: gray;
    margin: 0;
    padding: 0;
    font-weight: bolder;
    /* font-family: Monotype Corsiva, cursive; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  .text__header {
    width: 95%;
    margin: auto;
  }
  .text__p {
    width: 95%;
    margin: auto;
  }
}

@media screen and (max-device-width: 678px) {
  .webdev {
    display: flex;
    width: 95%;
    margin: auto;
    padding: 15px 0;
  }
  .coureName {
    border: 1.9px solid gray;
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    padding: 5px;
  }
  .coureName > img {
    height: 400px;
    width: 100%;
  }
  .coureName > h1 {
    font-size: 1.7rem;
    color: gray;
    margin: 0;
    padding: 0;
    font-weight: bolder;
    /* font-family: Monotype Corsiva, cursive; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  .coureName > p {
    font-style: 1.7rem;
    text-align: center;
  }
  .coureName {
    text-align: center;
  }
  .text__header {
    width: 95%;
    margin: auto;
  }
  .text__p {
    width: 95%;
    margin: auto;
  }
  /* powerfull message */
  .powerfull__message {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    padding: 50px 0;
    background-color: #fff;
    border-radius: 20px;
    padding: 15px;
    justify-content: space-between;
  }
  .powerful__right__card {
    width: 100%;
    border-radius: 15px;
    background-color: #0f73ed;
    padding: 20px;
    text-align: center;
    color: #fff;
  }
  .heading__h3 {
    font-size: 1.5rem;
    color: gray;
    margin: 0;
    padding: 0;
    font-weight: bolder;
    /* font-family: Monotype Corsiva, cursive; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .webdev {
    display: flex;
    width: 95%;
    margin: auto;
    padding: 15px 0;
  }
  .coureName {
    border: 1.9px solid gray;
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    padding: 5px;
  }
  .coureName > img {
    height: 350px;
    width: 100%;
  }
  .coureName > h1 {
    font-size: 1.7rem;
    color: gray;
    margin: 0;
    padding: 0;
    font-weight: bolder;
    /* font-family: Monotype Corsiva, cursive; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
  .coureName > p {
    font-style: 1.5rem;
    text-align: center;
  }
  .coureName {
    text-align: center;
  }
  .text__header {
    width: 95%;
    margin: auto;
  }
  .text__p {
    width: 95%;
    margin: auto;
  }
}
