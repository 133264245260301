/* page1 */
.heading__plan > h1 {
  padding: 0;
  margin: 0;
}
.oneplan__quarantine {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
}
.heading__plan {
  background-color: #f9f9f9;
  border-radius: 25px;
  padding: 50px;
}
.oneplan__quarantine > p {
  padding: 10px;
}
/* box */
.oneplan__second {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: auto;
  background-color: #f1eef9;
  padding: 15px;
}

.oneplan__second > div {
  background-color: #fff;
  text-align: center;

  padding: 10px;
  border-radius: 25px;
  width: 290px;
}
.oneplan__second > div > h1 {
  color: #6633ff;
  padding: 0;
  margin: 0;
}
/* page2 */
.paragraph__plan {
  background: linear-gradient(to top, #c86492 0%, #6633ff 100%);
  color: #fff;
  padding: 60px;
}
.paragraph__oneplan {
  font-size: 1.5rem;
}
.oneplan__divpic {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.oneplan__button {
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 10px;
  background-color: transparent;
  text-transform: uppercase;
  color: #fff;
}
.oneplan__button:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

/* media querry  */
@media screen and (max-width: 450px) {
  .oneplan {
    width: 100%;
    margin: auto;
  }
  .oneplan__quarantine {
    display: flex;
    flex-direction: column;
    width: 97%;
    margin: auto;
  }
  .heading__plan {
    background-color: #f9f9f9;
    /* border-radius: 25px;
    padding: 50px; */
  }
  .oneplan__quarantine > p {
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .oneplan__divpic {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }
  .paragraph__plan {
    width: 100%;
    margin: auto;
  }
  .paragraph__plan {
    padding: 10px;
    text-align: center;
  }
  .oneplan__second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .oneplan__second > div {
    margin: 10px 0;
    width: 100%;
  }
  .heading__plan > h1 {
    font-size: 1.7rem;
    text-align: center;
  }
  .oneplan__images{
    height: 100%;;
    width: 100%;
    margin: auto;
  }
}
/* media querry 66px */
@media screen and (max-width: 768px) {
  .oneplan {
    width: 100%;
    margin: auto;
  }
  .oneplan__quarantine {
    display: flex;
    flex-direction: column;
    width: 97%;
    margin: auto;
  }

  .heading__plan {
    background-color: #f9f9f9;
    width: 100%;
    margin: auto;
    /* border-radius: 25px;
    
    padding: 50px; */
  }
  .oneplan__quarantine > p {
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .oneplan__divpic {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }
  .paragraph__plan {
    width: 100%;
    margin: auto;
  }
  .paragraph__plan {
    padding: 10px;
    text-align: center;
  }
  .oneplan__second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .oneplan__second > div {
    margin: 10px 0;
    width: 100%;
  }
  .heading__plan > h1 {
    font-size: 1.7rem;
    text-align: center;
  }
}
/* media querry 820px max */
@media screen and (max-width: 820px) {
  .oneplan__quarantine {
    display: flex;
    justify-content: space-between;
  }
  .heading__plan > h1 {
    font-size: 1rem;
  }
  .oneplan__quarantine > p {
    width: 90%;
    margin: auto;
    font-size: 0.9rem;
  }
  .oneplan__second h1 p {
    font-size: 1rem;
  }
  .oneplan__divpic {
    width: 100%;
    margin: auto;
  }
  .paragraph__plan {
    width: 100%;
    padding: 10px;
    align-items: center;
  }
  .paragraph__oneplan {
    font-size: 0.9rem;
  }
  .paragraph__plan>p{
    padding:0;
    margin: 0;
  }
  .paragraph__plan > h1 {
    margin: 0;
    padding: 0;
  }
  .oneplan__images {
    height: 100%;
    object-fit: contain;
    width: 40%;
  }
}
