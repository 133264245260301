.global__issue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
  /* background: #000; */
}
.global__issue > h1 {
  padding: 5px;
  text-align: center;
  font-family: sans-serif;
  font-weight: bolder;
  font-style: italic;
  color: gray;
}
.global__next__page {
  width: 85%;
  margin: auto;
}
/* second */
.second {
  background-color: #ffffff;
  padding: 25px 0;
}
 
.global__second__page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
  align-items: center;
}
.global__next__page > h1  {
  color: gray;
  font-family: sans-serif;
  font-weight: bolder;
}
.global__next__page>h3{
  color: gray;
  font-family: sans-serif;
  font-weight: bolder;
}
.second__page__text__global>h3{
  color: gray;
  font-family: sans-serif;
  font-weight: bolder;
}
.global__second__page > p {
  padding: 5px;
}
.global__second__page > img {
  margin-right: 10px;
}
.second__page__text__global > h1 {
  color: gray;
  font-family: sans-serif;
  font-weight: bolder;
}
/* our programmes */
.our__program__global {
  padding: 20px 0;
}
.our__program__global > h1 {
  color: gray;
  font-family: sans-serif;
  font-weight: bolder;
}
/* card */
.cardx {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.card__global {
  align-items: center;
  width: 300px;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: -4px 7px 16px -4px rgba(24, 72, 194, 0.82);
}
.cards_from__card {
  width: 95%;
  margin: auto;
  /* padding:25px 0; */
}
@media only screen and (max-width: 450px) {
  .global__issue {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    width: 97%;
    margin: auto;
  }
  .global__second__page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 97%;
    margin: auto;
  }
  .cardx {
    width: 97%;
    margin: auto;
  }
  .card__global {
    width: 100%;
    margin: auto;
  }
}
