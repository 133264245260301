.alumni {
    background-color: #1E1E2D;
    padding: 50px 0 50px 0;
    text-align: center;
    color: #fff;
}

.alumni>img {
    height: 70px;
    width: 50px;
    padding: 15px 0;
}

.alumni>img:hover {
    cursor: pointer;
    transform: ease 0.5s;
}

.alumni>p {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
}

.alumni_button {
    background-color: #42A0F0;
    padding: 15px;
    border-radius: 30px;
    color: #fff;
    border: none;
}

.alumni_buttonred {
    background-color: #f01111;
    padding: 15px;
    border-radius: 30px;
    color: #fff;
    border: none;
}

.alumni_buttonred:hover {
    cursor: pointer;
}

.alumni_button:hover {
    cursor: pointer;
}

.alumni>h1 {
    color: white;
    font-size: 1.5rem;
    font-family: opentype, sans-serif;
}

@media only screen and (max-width: 900px) {
    .alumni>p {
        width: 60%;
        margin: auto;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 460px) {
    .alumni>p {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }
}