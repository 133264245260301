/* header freebook */
.freebook__header {
  background-image: url("./cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  text-align: center;
}
.fontsize__header {
  font-size: 3rem;
  font-weight: bolder;
  color: white;
}

.freebook__header__button {
  border: none;
  padding: 15px;
  background-color: #fbbc04;
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
}
.freebook__header__button_red {
  border: none;
  padding: 15px;
  background-color: red;
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
}
.freebook__header__button_red:hover {
  background-color: #fc7681;
  cursor: pointer;
  color: #fff;
}
.freeboob__header__youtube {
  padding: 20px;
}
.buttom__text {
  font-size: 1.5rem;
  padding: 20px 0;
}
/* freebook testimonial */
.freebook__testimonial{
  width:80%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.social__media__connect-1{
 color: #FFC107;
 padding: 0;
 margin: 0;
}
/* testimonial text */
.testimonial__card{
  width:500px;
  justify-content: space-between;
}
/* .testimonial__imagecountry */
.testimonial__imagecountry{
  height: 60px;
  width: 70px;
  object-fit:contain;
}
.testimonial__card{
  display: flex;
}
.testimonial__imagecountry{
  margin:15px;
}
/* form freebok */
.form__Ebooknow {
  width: 60%;
  margin: auto;
  padding: 40px 0;
  font-size: 1.5rem;
}
.form__ebook {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: auto;
}
.form__ebook > button {
  margin-top: 20px;
  border: none;
  background-color: #df2033;

  padding: 6px;
  font-weight: bolder;
  color: white;
  margin: 10px 0 200px 0;
}
.form__ebook > button:hover {
  cursor: pointer;
  border: 2px solid blue;
}
.freebook__fixed {
  position: fixed;
  bottom: 0;
  background-color: #df2033;
  width: 100%;
  margin: auto;
  padding: 30px 0;
  align-items: center;
  text-align: center;
}

.social__media__connect {
  color: yellow;
}

.freebook__fixed > h1 {
  color: #fff;
}
/* what you will get */
.freeboo__what__you__get {
  width: 80%;
  margin: auto;
  padding: 20px 0;
}
.section__image1 {
  width: 100%;
  object-fit: contain;
}
.freebook8820 > p {
  text-align: center;
  font-size: 1.5rem;
}
.freebook__middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.freebook__middle > p {
  font-size: 1.5rem;
}

/* greate__news */
.greate__news {
  background-color: #fff;
  padding: 20px 0;
}
.great__extern {
  width: 80%;
  margin: auto;
}
.great__extern > p {
  font-size: 1.5rem;
}
/* mindful */
.freebook__mindful {
  width: 80%;
  margin: auto;
  padding: 20px 0;
}
.freebook__mindful > p {
  font-size: 1.5rem;
}

/* mobile view */
@media screen and (max-width: 446px) {
  /* header freebook */
  .freebook__header {
    background-image: url("./cover.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0;
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .fontsize__header {
    font-size: 1.5rem;
    font-weight: bolder;
    color: white;
  }

  .freebook__header__button {
    border: none;
    padding: 15px;
    background-color: #fbbc04;
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
  }
  .freebook__header__button_red {
    border: none;
    padding: 10px;
    background-color: red;
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
  }
  .freebook__header__button_red:hover {
    background-color: #fc7681;
    cursor: pointer;
    color: #fff;
  }
  .freeboob__header__youtube {
    padding: 10px;
  }
  .buttom__text {
    font-size: 1rem;
    padding: 10px 0;
  }
  /* testimonial */
  /* freebook testimonial */
.freebook__testimonial{
  width:80%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.social__media__connect-1{
 color: #FFC107;
 padding: 0;
 margin: 0;
}
/* testimonial text */
.freebook__testimonial{
  width:90%;
  margin: auto;
}
.testimonial__card{
  width:100%;
  justify-content: space-between;
}
/* .testimonial__imagecountry */
.testimonial__imagecountry{
  height: 60px;
  width: 70px;
  object-fit:contain;
}
.testimonial__card{
  display: flex;
  flex-direction: column;
}
.testimonial__imagecountry{
  margin:5px;
}
  /* form */
  .form__Ebooknow {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    font-size: 1.5rem;
  }
  .form__Ebooknow > h2 {
    font-size: 1.5rem;
    color: gray;
  }
  .form__ebook {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
  .form__ebook > button {
    margin-top: 20px;
    border: none;
    background-color: #df2033;
    padding: 6px;
    font-weight: bolder;
    color: white;
    margin: 10px 0 20px 0;
  }
  .form__ebook {
    padding-bottom: 100px;
  }
  .form__ebook > input {
    padding: 5px;
    border: 1px solid rgb(55, 55, 88);
  }
  .freebook__fixed {
    position: fixed;
    bottom: 0;
    background-color: #df2033;
    width: 100%;
    margin: auto;
    padding: 10px 0;
    align-items: center;
    text-align: center;
  }
  .freebook__fixed > h1 {
    font-size: 1.5rem;
  }
  .freebook__mindful > h2 {
    font-size: 1.5rem;
    color: gray;
  }
  .great__extern > h2 {
    font-size: 1.5rem;
    color: gray;
  }
  .freeboo__what__you__get > h1 {
    font-size: 1.5rem;
    color: gray;
  }
}
/* ipad mini view */
@media screen and (max-width: 768px) {
  .form__Ebooknow {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    font-size: 1.5rem;
  }
  .form__Ebooknow > h2 {
    font-size: 1.5rem;
    color: gray;
  }
  .form__ebook {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
  }
  .form__ebook > button {
    margin-top: 20px;
    border: none;
    background-color: #df2033;
    padding: 6px;
    font-weight: bolder;
    color: white;
    margin: 10px 0 20px 0;
    font-size: 1.5rem;
  }
  .form__ebook > input {
    padding: 10px;
    border: 1px solid rgb(55, 55, 88);
  }
   /* testimonial */
  /* freebook testimonial */
.freebook__testimonial{
  width:80%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.social__media__connect-1{
 color: #FFC107;
 padding: 0;
 margin: 0;
}
/* testimonial text */
.freebook__testimonial{
  width:90%;
  margin: auto;
}
.testimonial__card{
  width:100%;
  justify-content: space-between;
}
/* .testimonial__imagecountry */
.testimonial__imagecountry{
  height: 60px;
  width: 70px;
  object-fit:contain;
}
.testimonial__card{
  display: flex;
  flex-direction: column;
}
.testimonial__imagecountry{
  margin:5px;
}
}
/* ipad view */
@media screen and (max-width: 820px) {
  .form__Ebooknow {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    font-size: 1.7rem;
  }
  .form__Ebooknow > h2 {
    font-size: 1.5rem;
    color: gray;
  }
  .form__ebook {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
  }
  .form__ebook > button {
    margin-top: 20px;
    border: none;
    background-color: #df2033;
    padding: 6px;
    font-weight: bolder;
    font-size: 1.5rem;
    color: white;
    margin: 10px 0 20px 0;
  }
  .form__ebook > input {
    padding: 10px;
    border: 1px solid rgb(55, 55, 88);
  }
   /* testimonial */
  /* freebook testimonial */
.freebook__testimonial{
  width:80%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.social__media__connect-1{
 color: #FFC107;
 padding: 0;
 margin: 0;
}
/* testimonial text */
.freebook__testimonial{
  width:90%;
  margin: auto;
}
.testimonial__card{
  width:100%;
  justify-content: space-between;
}
/* .testimonial__imagecountry */
.testimonial__imagecountry{
  height: 60px;
  width: 70px;
  object-fit:contain;
}
.testimonial__card{
  display: flex;
  flex-direction: column;
}
.testimonial__imagecountry{
  margin:5px;
}
}
/* ipad view */
@media screen and (max-width: 912px) {
  .form__Ebooknow {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    font-size: 1.7rem;
  }
  .form__Ebooknow > h2 {
    font-size: 1.5rem;
    color: gray;
  }
  .form__ebook {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
  }
  .form__ebook > button {
    margin-top: 20px;
    border: none;
    background-color: #df2033;
    padding: 6px;
    font-weight: bolder;
    font-size: 1.5rem;
    color: white;
    margin: 10px 0 20px 0;
  }
  .form__ebook > input {
    padding: 10px;
    border: 1px solid rgb(55, 55, 88);
  }
   /* testimonial */
  /* freebook testimonial */
.freebook__testimonial{
  width:80%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.social__media__connect-1{
 color: #FFC107;
 padding: 0;
 margin: 0;
}
/* testimonial text */
.freebook__testimonial{
  width:90%;
  margin: auto;
}
.testimonial__card{
  width:100%;
  justify-content: space-between;
}
/* .testimonial__imagecountry */
.testimonial__imagecountry{
  height: 60px;
  width: 70px;
  object-fit:contain;
}
.testimonial__card{
  display: flex;
  flex-direction: column;
}
.testimonial__imagecountry{
  margin:5px;
}
}
