.ambassador {
  background-color: #ffbdbd;
  padding: 150px 300px;
  margin: 20px;
}
.ambassador > h1 {
  font-size: 3rem;
  font-weight: bolder;
}
/* abassador */
.ambassador__about {
  background-color: #ffffff;
}
.ambassador__about > h1 {
  color: #7a7a7a;
}
.text-paragraph {
  padding: 0 90px;
  font-size: 1rem;
}

/* ambassador details */
.ambassador__details {
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
  background-color: white;
}
.ambassador__text {
  margin: 0 50px 0 0;
}
.ambassador__picture {
  padding: 0 80px 0 100px;
  height: 400px;
  width: 100%;
}
.ambassador__button {
  background-color: red;
  color: #ffffff;
  padding: 15px 20px;
  font-size: 1rem;
  font-weight: bolder;
  border: none;
  border-radius: 15px;
  text-transform: uppercase;
}
.ambassador__button:hover {
  cursor: pointer;
}
/* ambassador description */
.ambassador__descriptions {
  padding: 100px 80px;
  background-color: #e1414f;
  display: flex;
}
.ambassador__intro {
  padding-right: 50px;
}
.ambassador__colomun {
  display: flex;
  flex-wrap: wrap;
}
.ambassador__card {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  height: 250px;
  width: 501px;
  padding: 10px;
}
.details_amber {
  text-align: center;
  padding: 20px 10px;
}
/* ambassador requirement */
.ambassador__required {
  display: flex;

  padding: 100px 60px;
  justify-content: space-between;
}
.ambassador__padding__requirement {
  padding-left: 20px;
  line-height: 20px;
}
.ambassador__padding__requirement_next__line {
  padding-left: 50px;
  line-height: 15px;
}
/* ambasasdor final page */
.ambassador__final__page {
  opacity: 1.5;
  justify-content: space-between;
  color: #fff;
  padding: 20px 80px;
  background-image: url(./2016-11-20-SANMUN01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.xbutton {
  margin-right: 15px;
}
.ambassador__final__inside {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.xbutton {
  padding: 15px 20px;
  border: none;
  background-color: darkRed;
  color: #fff;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 20px;
  margin-bottom: 15px;
}
.xbutton:hover {
  cursor: pointer;
  background-color: rgb(121, 61, 252);
}
.final__page__button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 446px) {
  .ambassador {
    background-color: #ffbdbd;
    padding: 50px 0;
    margin: 15px;
  }
  .ambassador > h1 {
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
  }
  /* ambasador about */
  .ambassador__about {
    background-color: #ffffff;
  }
  .ambassador__about > h1 {
    color: #7a7a7a;
    font-size: 0.5rem;
    padding: 0 10px;
  }
  .text-paragraph {
    width: 100%;
    font-size: 1rem;
    padding: 0 10px;
  }
  /* ambassador details */
  .ambassador__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    background-color: white;
  }
  .ambassador__picture {
    padding: 0;
    margin: 0;
    height: 400px;
    width: 100%;
  }

  .ambassador__picture {
    padding: 0 10px 0 10px;
    height: 200px;
    width: 200px;
    text-align: center;
    margin-top: 20px;
  }
  /* ambassador description */
  .ambassador__descriptions {
    padding: 20px 10px;
    background-color: #e1414f;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  .ambassador__intro {
    padding-right: 10px;
  }
  .ambassador__colomun {
    display: flex;
    flex-wrap: wrap;
  }
  .ambassador__card {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    height: 150px;
    width: 100%;
  }
  .details_amber {
    text-align: center;
    padding: 20px 10px;
  }
  /* ambassador requirement */
  .ambassador__required {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
    justify-content: space-between;
  }
  .ambassador__required > h4 {
    text-align: center;
  }
  .ambassador__padding__requirement {
    padding-left: 0px;
  }
  .ambassador__padding__requirement_next__line {
    padding-left: 0;
  }
  /* ambasasdor final page */
  .ambassador__final__page {
    justify-content: space-between;
    color: #fff;
    padding: 15px 10px;
    background-color: red;
  }
  .xbutton {
    margin-right: 15px;
  }
  .ambassador__final__inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .xbutton {
    padding: 15px 20px;
    border: none;
    background-color: darkRed;
    color: #fff;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .final__page__button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .ambassador {
    background-color: #ffbdbd;
    padding: 50px 0px;
    margin: 20px;
  }
  .ambassador > h1 {
    font-size: 3rem;
    font-weight: bolder;
    text-align: center;
  }
  /* ambasador about */
  .ambassador__about {
    background-color: #ffffff;
  }
  .ambassador__about > h1 {
    color: #7a7a7a;
    font-size: 1rem;
    padding: 0 10px;
  }
  .text-paragraph {
    width: 100%;
    font-size: 1rem;
    padding: 0 10px;
  }
  /* ambassador details */
  .ambassador__details {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: white;
    justify-content: space-evenly;
  }
  .ambassador__picture {
    height: 250px;
    width: 250px;
  }

  .ambassador__picture {
    padding: 0 10px 0 10px;
    height: 200px;
    width: 300px;
    text-align: center;
    margin-top: 20px;
  }
  /* ambassador description */
  .ambassador__descriptions {
    padding: 20px 10px;
    background-color: #e1414f;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  .ambassador__intro {
    padding-right: 10px;
  }
  .ambassador__colomun {
    display: flex;
    flex-wrap: wrap;
  }
  .ambassador__card {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    height: 250px;
    width: 100%;
    padding: 5px;
  }
  .details_amber {
    text-align: center;
    padding: 20px 10px;
  }
  /* ambassador requirement */
  .ambassador__required {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
    justify-content: space-between;
  }
  .ambassador__required > h4 {
    text-align: center;
  }
  .ambassador__padding__requirement {
    padding-left: 0px;
  }
  .ambassador__padding__requirement_next__line {
    padding-left: 0;
  }
  /* ambasasdor final page */
  .ambassador__final__page {
    justify-content: space-between;
    color: #fff;
    padding: 15px 10px;
    background-color: red;
  }
  .xbutton {
    margin-right: 15px;
  }
  .ambassador__final__inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .xbutton {
    padding: 15px 20px;
    border: none;
    background-color: darkRed;
    color: #fff;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .final__page__button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 820px) {
  .ambassador {
    background-color: #ffbdbd;
    padding: 200px 0px;
    margin: 20px;
  }
  .ambassador > h1 {
    font-size: 3rem;
    font-weight: bolder;
    text-align: center;
  }
  /* ambasador about */
  .ambassador__about {
    background-color: #ffffff;
  }
  .ambassador__about > h1 {
    color: #7a7a7a;
    font-size: 2rem;
    padding: 0 10px;
  }
  .text-paragraph {
    width: 100%;
    font-size: 1.5rem;
    padding: 0 10px;
  }
  /* ambassador details */
  .ambassador__details {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: white;
    justify-content: space-evenly;
  }
  .ambassador__picture {
    height: 250px;
    width: 250px;
  }

  .ambassador__picture {
    padding: 0 10px 0 10px;
    height: 200px;
    width: 300px;
    text-align: center;
    margin-top: 20px;
  }
  /* ambassador description */
  .ambassador__descriptions {
    padding: 20px 10px;
    background-color: #e1414f;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }
  .ambassador__intro {
    padding-right: 10px;
  }
  .ambassador__colomun {
    display: flex;
    flex-wrap: wrap;
  }
  .ambassador__card {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    height: 250px;
    width: 100%;
  }
  .details_amber {
    text-align: center;
    padding: 20px 10px;
  }
  /* ambassador requirement */
  .ambassador__required {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
    justify-content: space-between;
  }
  .ambassador__required > h4 {
    text-align: center;
  }
  .ambassador__padding__requirement {
    padding-left: 0px;
  }
  .ambassador__padding__requirement_next__line {
    padding-left: 0;
  }
  /* ambasasdor final page */
  .ambassador__final__page {
    justify-content: space-between;
    color: #fff;
    padding: 15px 10px;
    background-color: red;
  }
  .xbutton {
    margin-right: 15px;
  }
  .ambassador__final__inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .xbutton {
    padding: 15px 20px;
    border: none;
    background-color: darkRed;
    color: #fff;
    font-weight: bolder;
    font-size: 1rem;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .final__page__button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }
}
