.compus__ambasador {
  width: 98%;
  margin: auto;
  padding: 25px 0;
}
.compus__ambasador>h1{
    color:gray;
    font-weight: bolder;
}
.ambasador__compus{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center
}
.compus__ambasador__details {
  box-shadow: -4px -3px 45px 21px rgba(0, 0, 0, 0.35);
  width: 320px;
  padding: 10px;
  margin-bottom: 10px;
}
.ambasador__detail__contact > h3 {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.ambasador__detail__contact > p {
  padding: 0;
  margin: 0;
}
.ambasador__pic {
  border-radius: 10px;
}
 
@media only screen and (max-width: 540px) {
    .compus__ambasador {
        width: 98%;
        margin: auto;
        padding: 25px 0;
      }
      .compus__ambasador>h1{
          font-size: 1.5rem;
      }
      .ambasador__compus{
          display:flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center
      }
      .compus__ambasador__details {
        box-shadow: -4px -3px 45px 21px rgba(0, 0, 0, 0.35);
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
      }
      .ambasador__detail__contact > h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
        font-weight: bolder;
        color:rgb(34, 34, 6);
        font-family:Verdana, sans-serif;
      }
      .ambasador__detail__contact > p {
        padding: 0;
        margin: 0;
      }
      .ambasador__pic {
        border-radius: 10px;
      }
}

/* 820px */

@media only screen and (max-width: 820px) {
    .compus__ambasador {
        width: 98%;
        margin: auto;
        padding: 25px 0;
      }
      .compus__ambasador>h1{
          font-size: 1.5rem;
      }
      .ambasador__compus{
          display:flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center
      }
      .compus__ambasador__details {
        box-shadow: -4px -3px 45px 21px rgba(0, 0, 0, 0.35);
        /* width: 100%; */
        padding: 10px;
        margin-bottom: 10px;
      }
      .ambasador__detail__contact > h3 {
        font-size: 1rem;
        margin-bottom: 0;
        font-weight: bolder;
        color:rgb(34, 34, 6);
        font-family:Verdana, sans-serif;
      }
      .ambasador__detail__contact > p {
        padding: 0;
        margin: 0;
        font-size: 1rem;
        font-weight: bolder;
      }
      .ambasador__pic {
        border-radius: 10px;
      }
}

/* 768px */