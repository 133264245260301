.trending{
    background-color: #f5f5f5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.trendinggrid{
    width: 90%;
    margin: auto;
}

.trending__title{
    padding: 20px 0 0 0;
}
 
.cardsx{
    padding: 0px 0 20px 0;
    max-width: 1000px;
    display: grid;
    /* grid-template-columns:repeat(3, 1fr) ; */
    grid-template-columns:repeat(auto-fill, minmax(317px, 1fr)) ;
    gap: 20px;
    margin: auto;
}
.cardx{
    background-color: #02024F;
    padding-bottom: 15px;
    color: #fff;
    margin: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
width: 317px;
 
}
.card__image{
    border-radius: 10px 10px 0 0;
    width: 100%;
}
.card__details{
    text-align: left;
    color: #fff;
    
    padding: 15px;
}
.card__details>h6{
color: #fff;
 
}
    
.register__button{
    background-color: #FC2727;
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    border: none;
    color: #fff;
    font-weight: bolder;
    font-family: sans-serif;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 15px;
}
.register__button:hover{
    cursor: pointer;
}
.linkRoute{
    color: rgb(62, 62, 230);
}
.linkRoute:hover{
    color: #8282d5;
}
/* trending details */
.trendingDetails{
    background-color: #02024F;
    margin-bottom: 10px;
    padding-top: 30px;
     
}
.tendDetailContent{
    width: 80%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    padding: 30px 0;
   
}
  p {
    color: #fff;
}
 
.trendDContent>h5{
    color: #fff;
}
.trendButtonmDetail{
    width: 80%;
    max-width: 1000px;
    margin: auto;
    
}
.register__buttond{
    background-color: #FC2727;
    text-align: center;
    /* border-radius: 15px; */
    padding: 10px;
    width: 100%;
    border: none;
    color: #fff;
    font-weight: bolder;
    font-family: sans-serif;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
     
}
.register__buttond:hover{
    cursor: pointer;
}
.trendDetails__image{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.trendDContent{
    flex:50%;
}
.trendD__image{
    flex:50%;
    margin-right: 10px;
}
@media screen and (max-width:600px){
    .cardx{
        background-color: #02024F;
         
        color: #fff;
        margin-bottom: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 317px;
     
    }
         .dexcription{
            font-size: 18px;
         }
         .trendDContent{
            flex:100%;
        }
        .trendD__image{
            flex:100%;
            
        }
        .tendDetailContent{
            width: 90%;
            display: flex;
            flex-direction: column;
        }
        .trendDp{
            font-size: 16px;
        }
       
}