.online {
  /* background-image: url("./roundtables.gif"); */
  width: 100%;
  /* background-repeat: no-repeat;
    background-size: cover; */
  margin-bottom: 10px;
  background-color: #fff;
}
.contetent__2__firstpage {
  display: flex;
  justify-content: space-between;
}
.online__top-image > img {
  width: 90%;
}
.online__content {
  padding: 60px 0;
}

.online__content > h3 {
  color: #fff;
}

.online__content > h1 {
  font-size: 1.5rem;
  font-weight: border;
  line-height: 3rem;
  color: #ff8c00;
}

.button__yellow {
  background-color: #92d050;
  border: none;
  padding: 15px 25px;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: border;
}

.button__yellow:hover {
  cursor: pointer;
  color: #fff;
}

.online__secondpage__image {
  text-align: center;
  padding: 20px 0;
  width: 100%;
  /* background-color: #fff; */
}

.online__secondpage__image > img:hover {
  transition: ease 0.5s;
  cursor: pointer;
  animation: 1s ease infinite;
  transform: scale(1, 1);
}

.online__thirdpage-join {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}

.online__thirdpage {
  padding: 60px 0;
}

.online__thirdpage > h1 {
  color: red;
}

.online__thirdpage__image {
  width: 2200px;
  height: 250px;
}

.online__thirdpage__image > img {
  width: 500px;
  height: 250px;
}

.online__forthpage__content {
  padding: 50px 70px;
  background-color: rgb(129, 46, 46);
  color: #fff;
  justify-content: center;
  text-align: center;
  height: 300px;
  align-items: center;
}

.online__forthpage__content > h1 {
  color: white;
}

.online__forthpage__content > button {
  background-color: yellow;
  font-weight: border;
  font-size: 1rem;
  padding: 20px 30px;
  border-radius: 7px;
  border: none;
}

button {
  font-weight: border;
  font-size: 1rem;
}

.online__join-webinar {
  background-color: #fff;
  padding: 20px 0;
}

.red {
  padding: 30px 0;
  color: #ee2a50;
}

.oline__sixpages__benefit {
  text-align: center;
  background-image: url("./worldwide print network -1.gif");
  padding: 50px 0;
  color: #fff;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  background-size: cover;

  width: 100%;
  margin: auto;

  align-items: center;
}

.joinus__button {
  background-color: #e56125;
  padding: 20px 30px;
  border-radius: 7px;
  border: none;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #fff;
}
.joinus__button:hover {
  cursor: pointer;
  transition: 2s;
  animation: 1s ease infinite;
}

.online__sixpage__divide {
  display: flex;
  width: 30%;
  margin: auto;
  padding: 50px 0;
}

.online__fontbolder > h1 {
  color: #e56125;
  font-size: 5rem;
  font-weight: border;
}

.online__sixpage__divide-money > p {
  font-size: 2rem;
  color: black;
  text-decoration: line-through;
}
/* .webinar__imageF
    */
.webinar__image {
  width: 100%;
  margin: auto;
}
.online__sixpage__divide_h4 {
  color: #e79f2d;
  font-size: 2.6rem;
  font-weight: bolder;
}
/* quotes */
.quotes__and__pic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quoute__paragraph {
  font-weight: bolder;
  font-style: italic;
  padding-left: 10px;
}
/* .online__sixpage__divide-money> {
    color: black
} */
@media screen and (max-width: 450px) {
  .contetent__2__firstpage {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
  }
  .online__content > h1 {
    font-size: 1rem;
    font-weight: border;
    line-height: 2rem;
    color: #ff8c00;
  }
  .transformation__text {
    font-size: 1rem;
    font-weight: border;
    line-height: 2rem;
    color: #ff8c00;
  }
  .online__top-image {
    width: 100%;
    margin: auto;
  }
  .online__thirdpage > h1 {
    font-size: 1rem;
    font-weight: border;
    line-height: 2rem;
    color: #ff8c00;
  }
  .online__thirdpage-join {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
  .online__thirdpage__image {
    width: 80%;
    margin: auto;
  }
  .image__width {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .webinar__image {
    width: 100%;
    margin: auto;
  }
  .oline__sixpages__benefit {
    text-align: center;
    background-image: url("./worldwide print network -1.gif");
    padding: 50px 0;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin: auto;
    align-items: center;
  }
  .oline__sixpages__benefit > h1 {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  .online__sixpage__divide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    text-align: center;
  }
  .online__sixpage__divide-money > h4 {
    text-align: center;
    font-size: 1rem;
    display: flex;
    color: #fff;
  }
  /* quotes */
  .quotes__and__pic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
