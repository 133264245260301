 
@media only screen and (max-width: 450px) {
  /* .homepage__textcenter {
    width: 100%;
    margin-top:0;
    text-align: center;
    font-size: 3.6rem;
    text-transform: uppercase;
    padding:0;
  
  } */
  .homepage__mediaquery{
    align-items: center;
    width: 100%;
  }
  .homepage__mediaquery>h1{
    
    font-style: bold;
    width: 80%;
     margin:auto;
     padding: 10px 5px;
     text-align: center;
    
  }
  br {
    content: " ";
    display: none;
}
.about__us__text{
  font-size: 1rem;
}
}

