        .contact__details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        
        .contact__detail {
            flex-direction: column;
            color: gray;
            text-align: center;
        }
        
        .contact__detail>h1 {
            display: flex;
            flex-direction: column;
            padding: 0;
            font-size: 16px;
            margin: 0;
            color: gray;
        }
        
        .contact__detail>p {
            padding: 0;
            margin: 0
        }
        
        .contact__detail-image {
            padding: 20px 0
        }
        
        .contact__detail-map {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
        }
        
        .map1 {
            background-color: rgba(255, 255, 0, 0.75);
            height: 350px;
            width: 500px;
            margin: 15px
        }
        
        .map>h1 {
            font-size: 20px;
        }
        
        .contact__detail-address {
            display: flex;
            justify-content: space-between;
            padding: 10px
        }
        
        .aboutus {
            padding: 25px 0;
            background-color: #DF2033;
            color: white
        }
        
        .aboutus__left {
            display: flex;
            justify-content: space-between;
            color: white
        }
        
        .aboutus__text-left {
            padding-right: 400px;
        }
        
        .aboutus__seperate__line {
            border-left: 5px solid white;
            padding: 10px
        }
        
        .row2 {
            display: flex;
        }
        
        @media screen and (max-width: 900px) {
            .map1 {
                background-color: rgba(255, 255, 0, 0.75);
                height: 250px;
                width: 400px;
                margin: 15px
            }
            .map>h1 {
                font-size: 16px;
            }
        }
        
        @media screen and (max-width: 650px) {
            .map1 {
                background-color: rgba(255, 255, 0, 0.75);
                height: 250px;
                width: 97%;
                margin: 5px
            }
            .contact__detail-map {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 5px;
            }
            .contact__detail-address {
                flex-direction: column;
                justify-content: space-between;
                padding: 10px;
            }
            .map>h1 {
                font-size: 16px;
            }
            .contact__detail>p {
                font-size: 20px;
                font-weight: bolder;
            }
            .contact__detail>h1 {
                font-size: 20px;
                font-weight: bolder;
            }
            .aboutus__3pillars {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center
            }
            .aboutus {
                padding: 25px 0;
                background-color: #DF2033;
                color: white;
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                justify-content: space-between;
            }
            .aboutus__seperate__line {
                border-left: none;
            }
            .row {
                text-align: center;
            }
            .aboutus__text-left {
                text-align: center;
                padding-right: 0;
                align-items: center;
            }
            .row2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }
        }