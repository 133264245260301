.about__uss {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}
.about__us__images {
  padding: 10px;
}

.row2 {
  display: flex;
  justify-content: space-between;
}
.mission__text__paragraph {
  border-left: 4px solid #fff;
  margin-left: 10px;
  padding: 10px;
}
.messsion__text {
  padding-top: 40px;
}
@media only screen and (max-width: 450px) {
  .about__uss {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 0;
  }
  .about__us__images {
    padding: 10px;
    width: 100%;
  }
  .row2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mission__text__paragraph {
    border-top: 4px solid #fff;
    margin-left: 10px;
    padding: 10px;
  }
  .messsion__text {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .about__uss {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 0;
  }
  .about__us__images {
    padding: 10px;
    width: 100%;
  }
  .row2 {
    display: flex;
    justify-content: space-between;
  }
  .mission__text__paragraph {
    border-left: 4px solid #fff;
    margin-left: 10px;
    padding: 10px;
  }
  .messsion__text {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 820px) {
  .about__uss {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 0;
  }
  .about__us__images {
    padding: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 1280px) {
    .about__uss {
        display: flex;
        justify-content: space-between;
        padding: 25px 0;
      }
      .about__us__images {
        padding: 10px;
        width: 100%;
         
      }
}
/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .about__uss {
        display: flex;
        justify-content: space-between;
        padding: 25px 0;
      }
      .about__us__images {
        padding: 10px;
        width: 100%;
         
      }
}
