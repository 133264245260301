.sholarship {
  background-image: url(./mun-20193-compressor-1.jpg);
  background-repeat: no-repeat;
  background-position: contain;
  background-attachment: fixed;
  height: 100vh;
  padding: 200px 20px;
}
.sholarship > h1 {
  color: white;
  font-size: 3rem;
}
.sholarship > h3 {
  color: white;
}
.scholarship__button {
  padding: 30px;
  background-color: #ffb800;
  border: none;
  border-radius: 20px;
  font-size: 2rem;
  font-weight: bolder;
  color: black;
}
.scholarship__button:hover {
  cursor: pointer;
}
/* sholarship zone */
.sholarship__benefits {
  width: 100%;
  height: 100vh;
  margin: auto;
  padding: 20px 100px;
  background-image: url(./white-cube-pattern-4k-bu.jpg);
  background-attachment: fixed;
  border-image-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.scholarship__cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sholarship__div {
  width: 300px;
  height: 250px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.programme_image__Logo {
  width: 300px;
  height: 200px;
}

@media screen and (max-width: 446px) {
    .sholarship {
        background-image: url(./mun-20193-compressor-1.jpg); 
         background-repeat: no-repeat;
        background-position: contain;
        background-attachment: fixed;
       background-color: red;
        padding: 50px 10px;
      }
  .sholarship > h1 {
    color: white;
    font-size: 10px;
    font-weight: bolder;
  }
   
  .scholarship__button {
    background-color: #ffb800;
    border-radius: 10px;
    font-size: 16px;
    color: black;
    padding: 20px;
  }
  .scholarship__button:hover {
    cursor: pointer;
  }
  /* benefit */
  .sholarship__benefits {
    width: 100%;
    padding: 500px 10px;
    margin-top: 100px ;
    background-image: url(./white-cube-pattern-4k-bu.jpg);
    background-attachment: fixed;
    border-image-repeat: no-repeat;
    background-size: center;
    background-position: cover;

  }
  .scholarship__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sholarship__div {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .programme_image__Logo {
    width: 100%;
    height: 100px;
  }
}
@media screen and (max-width: 768px) {
  .sholarship {
    height: 100vh;
    padding: 300px 40px;
  }
  .sholarship > h1 {
    color: white;
    font-size: 3rem;
    font-weight: bolder;
  }
  .scholarship__button {
    background-color: #ffb800;
    border-radius: 20px;
    font-size: 25px;
    color: black;
    padding: 20px;
  }
  .scholarship__button:hover {
    cursor: pointer;
  }
   /* benefit */
   .sholarship__benefits {
    width: 100%;
    padding: 20px 10px;
    background-image: url(./white-cube-pattern-4k-bu.jpg);
    background-attachment: fixed;
    border-image-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .scholarship__cards {
    display: flex;
    flex-wrap: wrap;
  }
  .sholarship__div {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .programme_image__Logo {
    width: 100%;
    height: 350px;
  }
}
@media screen and (max-width: 820px) {
  .sholarship {
    height: 100vh;
    padding: 400px 40px;
  }
  .sholarship > h1 {
    color: white;
    font-size: 3rem;
    font-weight: bolder;
  }
  .scholarship__button {
    background-color: #ffb800;
    border-radius: 20px;
    font-size: 25px;
    color: black;
    padding: 20px;
  }
  .scholarship__button:hover {
    cursor: pointer;
  }
   /* benefit */
   .sholarship__benefits {
    width: 100%;
    padding: 20px 10px;
    background-image: url(./white-cube-pattern-4k-bu.jpg);
    background-attachment: fixed;
    border-image-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .scholarship__cards {
    display: flex;
    flex-wrap: wrap;
  }
  .sholarship__div {
    width: 100%;
    height: 550px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .programme_image__Logo {
    width: 100%;
    height: 550px;
  }
}
