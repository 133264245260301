.dream__research {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 15px;
}
.dream__text {
  margin-left: 10px;
}
p{
  color: #000;
}
.research__card {
  width: 350px;
  box-shadow:         3px 3px 5px 6px #ccc;
  padding: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.bullet__point{
  display:flex;
}
.bullet__point>span{margin-right: 5px;}

.button__research{
    padding: 7px;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid gray;
}
.button__research:hover{
    cursor: pointer;
    color:#fff;
    background-color:gray;
}
.research__card__bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.flex__cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
@media screen and (max-width: 450px) {
  .dream__research {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px;
  }
  .dream__text {
    margin-left: 10px;
  }
  .dream__text > p {
    text-align: center;
  }
  .research__card__text>p{
      text-align: center;
  }
  .research__card__bottom{
      display: flex;
      flex-direction: column;
  }
}
