.event__organizer{
    height: 100vh;
    background-image: url(./2016-11-20-SANMUN01.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.event__organizer__content{
    color:white;
    text-align: center;
    padding: 200px 0;

}
.button{
    padding:10px 50px;
    background-color: #B21A29;
    border-radius: 20px;
    color:#fff;
    border: none;
}
.event__organizer-whatsapp{
    text-align: center;
    background-color:blue;
    padding:70px
}
.event-organiser-section-3{
padding:20px 80px;
}
.event__organizer-section-programme{
    padding:20px;
}
.event__organizer-section-program-object{
    padding:5px;
    background-color:red; 
    margin:10px
}
.event__organizer-display-flex{
    display:flex;
    justify-content: space-between;
}
/* event organizer */
.event__organizer-about{
    display:flex;
    justify-content: space-between;
    padding: 20px 0;
    
}
.event__organizer_image{
    width:300px;
    height:300px;
    border-radius: 6px;
    
}
/* event right */
.event-organiser-right{
display:flex;
width:40%;

}
.event__organizer__ontop>.event__organizer_image{
    position: absolute;
    top: 1400px;
    left:280px;
    width:300Px;
    height:300px;
     
    padding:0 20px 0 0
}
/* event right organizer*/
.event-organiser-left{
    padding:0 50px;
    width:60%;
}
@media screen and (max-width: 446px) {
    .event__organizer{
     text-align: center; 
     width: 100%;
     padding:0 10px  ;
     height: 50vh;
    }
    .event__organizer__content{
        color:white;
        text-align: center;
         padding:15px 0;
    }
    .event__organizer__content>p{
        font-size:25px
    }
    .event__organizer-whatsapp{
        padding:20px 10px;
        font-size:16px;
        
    }
    .event__organizer-whatsapp>p{
        font-size:25px
    }
    .button{
        margin:20px 50px;
    }
    .event-organiser-section-3{
        padding:10px 15px;
    }
    .event-organiser-section-3>h1{
        font-size:20px;
    }
    .event__organizer-display-flex{
        display:flex;
        flex-direction: column;
    }
    .event__organizer-section-program-object{
        padding:0 0;
    }
    .event__organizer-section-programme{
        padding:0
    }
    p{
        font-size:25px
    }

    .event__organizer-about{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
         
        
    }
    .event__organizer_image{
        width:100%;
        height:300px;
        border-radius: 6px;
        margin: 5px 0
        
    }
    .event-organiser-right{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin:0 auto;
    }
    .event__organizer__ontop>.event__organizer_image{
       position: static;
       width:100%;
       margin:auto;
       top:0;
       left:0;
       width:100%;
       height:300px;
       margin:0;
       padding:0   
    }
    .event-organiser-left{
        width: 100%;
        margin: auto;
        padding:0 10px

    }
    .event-organiser-left>h2{
        font-size: 25px;
    }
}

@media screen and (max-width: 820px) {
    .event__organizer{
        text-align: center; 
        width: 100%;
        padding:0 10px  ;
        height: 50vh;
       }

    p{
        font-size:25px
    }
    .event__organizer-about{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
         
        
    }
    .event__organizer_image{
        width:100%;
        height:100%;
        border-radius: 6px;
        margin: 5px 0
        
    }
    .event-organiser-right{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin:0 auto;
    }
    .event__organizer__ontop>.event__organizer_image{
       position: static;
       width:100%;
       margin:auto;
       top:0;
       left:0;
       width:100%;
       height:100%;
       margin:0;
       padding:0   
    }
    .event-organiser-left{
        width: 100%;
        margin: auto;
        padding:0 10px

    }
    .event-organiser-left>h2{
        font-size: 25px;
    }
    .event-organiser-left>p{font-size: 25px;}
}
@media screen and (max-width: 768px) {
    .event__organizer{
        text-align: center; 
        width: 100%;
        padding:0 10px  ;
        height: 70vh;
       }

    p{
        font-size:25px
    }
     
    p{
        font-size:25px
    }
    .event__organizer-about{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
         
        
    }
    .event__organizer_image{
        width:100%;
        height:100%;
        border-radius: 6px;
        margin: 5px 0
        
    }
    .event-organiser-right{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin:0 auto;
    }
    .event__organizer__ontop>.event__organizer_image{
       position: static;
       width:100%;
       margin:auto;
       top:0;
       left:0;
       width:100%;
       height:100%;
       margin:0;
       padding:0   
    }
    .event-organiser-left{
        width: 100%;
        margin: auto;
        padding:0 10px

    }
    .event-organiser-left>h2{
        font-size: 25px;
    }
    .event-organiser-left>p{font-size: 25px;}
}