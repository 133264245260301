.free__join {
  background-image: url("./back.jpg");
}
.webinar__y2y {
  width: 85%;
  margin: auto;
  padding: 25px 0;
  display: flex;
  justify-content: space-evenly;
}
.action__button__read__more:hover {
  cursor: pointer;
}
.webinar__card {
  background-color: #fff;
  width: 300px;
  margin: 10px;
  -webkit-box-shadow: -1px 0px 9px 5px rgba(107, 89, 122, 1);
  -moz-box-shadow: -1px 0px 9px 5px rgba(107, 89, 122, 1);
  box-shadow: -1px 0px 9px 5px rgba(107, 89, 122, 1);
  padding: 10px;
}
.button__webinar {
  cursor: pointer;
}
.webinar__card > img {
  height: 200px;
}
.cards__science {
  width: 96%;
  margin: auto;
}
@media only screen and (max-width: 450px) {
  .webinar__y2y {
    width: 96%;
    margin: auto;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .webinar__card {
    width: 100%;
    margin: 10px;
    -webkit-box-shadow: -1px 0px 9px 5px rgba(107, 89, 122, 1);
    -moz-box-shadow: -1px 0px 9px 5px rgba(107, 89, 122, 1);
    box-shadow: -1px 0px 9px 5px rgba(107, 89, 122, 1);
    padding: 10px;
  }
  .action__button {
    text-align: center;
  }
  .webinar__card > img {
    height: 200px;
    width: 100%;
  }
}
