.science__tech {
  background-image: url(./images/rocket.gif);
  padding: 200px 0;
  /* background-repeat: no-repeat; */

  align-items: center;
}
.science__tech > h1 {
  color: white;
  font-weight: bolder;
  font-size: 2rem;
  width: 85%;
  margin: auto;
}
.science__tech > p {
  color: white;
  font-weight: bolder;
  font-size: 1rem;
  width: 85%;
  margin: auto;
  padding-top: 20px;
}
.science__tech_fp {
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
  padding: 25px 0;
  /* background-color: #fff; */
}
.science__tech_fp > p {
  padding: 10px;
}
.science_x {
  display: flex;
}
.science_x > span {
  margin-left: 10px;
}
.science_x > p {
  font-size: 1.5rem;
  margin-left: 8px;
}
/* cards */
.cards_display {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
}
.card{
  width:400px;
}
.card > img {
  height: 220px;
}
.cards__science {
  width: 96%;
  margin: auto;
}
/* tech_details */
.tech__details {
  display: flex;
  align-items: center;
  width: 85%;
  margin: auto;
  padding: 20px 0;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
.tech__details > div {
  margin-right: 10px;
  padding: 5px;
}
.tech__details > img {
  padding: 5px;
}
.tech__pragraph {
  width: 85%;
  margin: auto;
  padding: 25px 0;
}
.point__bullet {
  display: flex;
}
.point__bullet>span{margin-right: 5px;}
@media screen and (max-width: 450px) {
  .science__tech_fp {
    display: flex;
    flex-wrap: wrap;
  }
  /* tech_details */
  .tech__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    text-align: center;
    margin: auto;
    padding: 20px 0;
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  }
  .tech__details > div > h5 {
    font-weight: bolder;
    font-size: 1.5rem;
  }
  .tech__details > div {
    margin-right: 10px;
    padding: 5px;
  }
  .tech__details > img {
    padding: 5px;
  }
  .card{
    width:100%;
    margin: auto;
  }
}

@media only screen and (max-width:820px) {
  .science__tech_fp{
    display: flex;
    flex-direction: column;
  }
}